import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-2" }
const _hoisted_2 = { class: "col-12 p-0" }
const _hoisted_3 = {
  class: "flex col-12 justify-content-end pl-0 pr-0",
  style: { flexDirection: 'row' }
}
const _hoisted_4 = { style: {"text-align":"right !important","padding-right":"3px"} }
const _hoisted_5 = { class: "grid" }
const _hoisted_6 = { class: "col-12 grid p-0 m-0" }
const _hoisted_7 = { class: "col-12 grid lg:col-4 xl:col-3 m-0 p-0 align-content-start" }
const _hoisted_8 = { class: "col-12 md:col-6 lg:col-12" }
const _hoisted_9 = { class: "p-inputgroup" }
const _hoisted_10 = { class: "col-12 md:col-6 lg:col-12" }
const _hoisted_11 = { class: "p-inputgroup" }
const _hoisted_12 = { class: "col-12 grid lg:col-4 xl:col-3 m-0 p-0 align-content-start" }
const _hoisted_13 = { class: "col-12 md:col-6 lg:col-12" }
const _hoisted_14 = { class: "p-inputgroup" }
const _hoisted_15 = { class: "col-12 md:col-6 lg:col-12" }
const _hoisted_16 = { class: "p-inputgroup" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "p-error" }
const _hoisted_19 = { class: "col-12 grid lg:col-4 xl:col-3 m-0 p-0 align-content-start" }
const _hoisted_20 = { class: "col-12 md:col-6 lg:col-12" }
const _hoisted_21 = { class: "p-inputgroup" }
const _hoisted_22 = { class: "col-12 md:col-6 lg:col-12" }
const _hoisted_23 = { class: "p-inputgroup" }
const _hoisted_24 = { class: "col-12 grid lg:col-4 xl:col-3 m-0 p-0 align-content-start" }
const _hoisted_25 = { class: "col-12 md:col-6 lg:col-12" }
const _hoisted_26 = { class: "p-inputgroup" }
const _hoisted_27 = ["href"]
const _hoisted_28 = { class: "col-12 md:col-6 lg:col-12" }
const _hoisted_29 = { class: "p-inputgroup" }
const _hoisted_30 = ["href"]
const _hoisted_31 = { class: "col-12 md:col-6 lg:col-4 xl:col-3" }
const _hoisted_32 = { class: "p-inputgroup" }
const _hoisted_33 = { class: "col-12 md:col-6 lg:col-4 xl:col-3" }
const _hoisted_34 = { class: "p-inputgroup" }
const _hoisted_35 = { class: "col-12 md:col-6 lg:col-4 xl:col-3" }
const _hoisted_36 = { class: "p-inputgroup" }
const _hoisted_37 = { class: "col-12 md:col-6 lg:col-4 xl:col-3" }
const _hoisted_38 = { class: "p-inputgroup" }
const _hoisted_39 = { class: "col-12 md:col-6 lg:col-4 xl:col-3" }
const _hoisted_40 = { class: "p-inputgroup" }
const _hoisted_41 = { class: "col-12 md:col-6 lg:col-4 xl:col-3" }
const _hoisted_42 = { class: "p-inputgroup" }
const _hoisted_43 = { class: "col-12 md:col-6 lg:col-4 xl:col-3" }
const _hoisted_44 = { class: "p-inputgroup" }
const _hoisted_45 = { class: "col-12 md:col-6 lg:col-4 xl:col-3" }
const _hoisted_46 = { class: "p-inputgroup" }
const _hoisted_47 = { class: "col-12 md:col-6 lg:col-4 xl:col-3" }
const _hoisted_48 = { class: "p-inputgroup" }
const _hoisted_49 = { class: "col-12 md:col-6 lg:col-4 xl:col-3" }
const _hoisted_50 = { class: "p-inputgroup" }
const _hoisted_51 = { class: "col-12 p-inputgroup" }
const _hoisted_52 = {
  key: 0,
  class: "col-12",
  style: {"display":"flex","justify-content":"space-between"}
}
const _hoisted_53 = { class: "grid pb-3 justify-content-around" }
const _hoisted_54 = { class: "grid col-12 lg:col-6 pt-1 align-content-start" }
const _hoisted_55 = {
  key: 1,
  class: "col-12 p-0 m-0"
}
const _hoisted_56 = { class: "flex justify-content-between align-items-center" }
const _hoisted_57 = { class: "flex justify-content-start" }
const _hoisted_58 = {
  style: { height: '30px', left: '2rem' },
  class: "flex align-items-center justify-content-center speed-dial-container pr-3 xl:pr-1"
}
const _hoisted_59 = { class: "grid col-12 lg:col-6 pt-1 pr-1 align-content-start" }
const _hoisted_60 = {
  key: 1,
  class: "col-12 p-0 m-0"
}
const _hoisted_61 = { class: "flex justify-content-between align-items-center" }
const _hoisted_62 = { key: 0 }
const _hoisted_63 = { key: 1 }
const _hoisted_64 = { key: 0 }
const _hoisted_65 = { key: 1 }
const _hoisted_66 = { class: "flex justify-content-start" }
const _hoisted_67 = {
  style: { height: '30px', left: '2rem' },
  class: "flex align-items-center justify-content-center speed-dial-container pr-3 xl:pr-1"
}
const _hoisted_68 = { class: "col-12 p-1 m-0 pb-4" }
const _hoisted_69 = { class: "grid" }
const _hoisted_70 = { class: "grid col-12 pt-0 pr-1 align-content-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OpportunitiesTabView = _resolveComponent("OpportunitiesTabView")!
  const _component_Button = _resolveComponent("Button")!
  const _component_StatusDisplayBar = _resolveComponent("StatusDisplayBar")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Search = _resolveComponent("Search")!
  const _component_ContactAutocomplete = _resolveComponent("ContactAutocomplete")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_Column = _resolveComponent("Column")!
  const _component_SpeedDial = _resolveComponent("SpeedDial")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_CollapsibleSection = _resolveComponent("CollapsibleSection")!
  const _component_LogsDataTable = _resolveComponent("LogsDataTable")!
  const _component_AttachmentRecords = _resolveComponent("AttachmentRecords")!
  const _component_Card = _resolveComponent("Card")!
  const _component_QuoteAutocomplete = _resolveComponent("QuoteAutocomplete")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_OrderAutocomplete = _resolveComponent("OrderAutocomplete")!
  const _component_EmailFileDialog = _resolveComponent("EmailFileDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _createVNode(_component_OpportunitiesTabView)
      ], 512), [
        [_vShow, _ctx.getRoute]
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isReadOnly)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                label: "Save Opportunity",
                icon: "pi pi-check",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.save())),
                loading: _ctx.submitLoading,
                style: { 'margin-right': '10px' }
              }, null, 8, ["loading"]))
            : _createCommentVNode("", true),
          (_ctx.isCustomerModule)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                label: "Close",
                icon: "pi pi-times",
                onClick: _ctx.exitSalesOpps
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _createVNode(_component_StatusDisplayBar, {
            filteredStatusData: _ctx.filteredStatusData,
            progressStatus: _ctx.progressStatus,
            statusBarLength: _ctx.statusBarLength,
            showPreviousStatus: false
          }, null, 8, ["filteredStatusData", "progressStatus", "statusBarLength"]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _cache[47] || (_cache[47] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "ID", -1)),
                      _createVNode(_component_InputText, {
                        value: _ctx.replaceIdString(_ctx.saleOpp.id),
                        class: "text-left",
                        disabled: true
                      }, null, 8, ["value"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _cache[48] || (_cache[48] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Date", -1)),
                      _createVNode(_component_Calendar, {
                        modelValue: _ctx.saleOpp.date,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.saleOpp.date) = $event)),
                        dateFormat: "mm-dd-yy",
                        showButtonBar: true,
                        showIcon: true,
                        onDateSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.formatDate($event, 'date')))
                      }, null, 8, ["modelValue"])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _cache[49] || (_cache[49] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Prospect", -1)),
                      _createVNode(_component_Search, {
                        modelValue: _ctx.saleOpp.prospectName,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.saleOpp.prospectName) = $event)),
                        onFilter: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setProspect($event.value))),
                        label: "name",
                        file: "PROSPECT",
                        minimumCharacters: 2,
                        placeholder: "Search by ID or Name",
                        class: "prospect-search"
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _cache[50] || (_cache[50] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Customer", -1)),
                      (_ctx.isCustomerModule)
                        ? (_openBlock(), _createBlock(_component_InputText, {
                            key: 0,
                            modelValue: this.getActiveIndexCustomer.customer.name,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.getActiveIndexCustomer.customer.name) = $event)),
                            disabled: true
                          }, null, 8, ["modelValue"]))
                        : (_openBlock(), _createBlock(_component_Search, {
                            key: 1,
                            modelValue: _ctx.saleOpp.cust_name,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.saleOpp.cust_name) = $event)),
                            placeholder: "Search by ID or Name",
                            file: "NEWSALES",
                            label: "name",
                            onFilterCust: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setCustomer($event.value))),
                            class: _normalizeClass({
                        'cust-search': true,
                        'p-invalid':
                          _ctx.isFormSubmitted &&
                          (_ctx.v$.customer as any).name.required.$invalid,
                      })
                          }, null, 8, ["modelValue", "class"]))
                    ]),
                    (
                      _ctx.isFormSubmitted &&
                      (_ctx.v$.customer as any).name.required.$invalid
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          _createElementVNode("small", _hoisted_18, _toDisplayString((_ctx.v$.customer as any).name.required.$message), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _cache[51] || (_cache[51] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Contact", -1)),
                      _createVNode(_component_ContactAutocomplete, {
                        "selected-id": _ctx.saleOpp.contact,
                        onItemSelect: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handleContactSelected($event))),
                        customerContacts: _ctx.customerContacts,
                        class: "contact-search",
                        "contact-lookup": _ctx.getContactLookup,
                        "cust-id": _ctx.saleOpp.cust
                      }, null, 8, ["selected-id", "customerContacts", "contact-lookup", "cust-id"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      _cache[52] || (_cache[52] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Role", -1)),
                      _createVNode(_component_InputText, {
                        modelValue: _ctx.contactJobTitle,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.contactJobTitle) = $event)),
                        class: "text-left",
                        disabled: true
                      }, null, 8, ["modelValue"])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _cache[53] || (_cache[53] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Email", -1)),
                      _createElementVNode("a", {
                        href: 'mailto:' + _ctx.contactEmail,
                        style: _normalizeStyle(_ctx.inputStyle(_ctx.contactEmail))
                      }, _toDisplayString(_ctx.contactEmail), 13, _hoisted_27)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, [
                      _cache[54] || (_cache[54] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Phone", -1)),
                      _createElementVNode("a", {
                        href: 'tel:' + _ctx.contactPhone,
                        style: _normalizeStyle(_ctx.inputStyle(_ctx.contactPhone))
                      }, _toDisplayString(_ctx.contactPhone), 13, _hoisted_30)
                    ])
                  ])
                ])
              ]),
              _cache[65] || (_cache[65] = _createElementVNode("div", { style: {"height":"1px","background-color":"#e9ecef","width":"100%","margin-bottom":"5px","margin-top":"5px"} }, null, -1)),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, [
                  _cache[55] || (_cache[55] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Status", -1)),
                  _createVNode(_component_Dropdown, {
                    modelValue: _ctx.saleOpp.status,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.saleOpp.status) = $event)),
                    options: _ctx.control.saleOpportunity.status_items,
                    optionLabel: "status",
                    optionValue: "status",
                    showClear: _ctx.saleOpp.status != ''
                  }, null, 8, ["modelValue", "options", "showClear"])
                ])
              ]),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, [
                  _cache[56] || (_cache[56] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Value $", -1)),
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.saleOpp.amount,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.saleOpp.amount) = $event)),
                    mode: "currency",
                    currency: "USD",
                    locale: "en-US",
                    minFractionDigits: 2,
                    maxFractionDigits: 2
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("div", _hoisted_36, [
                  _cache[57] || (_cache[57] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Est Close", -1)),
                  _createVNode(_component_Calendar, {
                    modelValue: _ctx.saleOpp.est_close,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.saleOpp.est_close) = $event)),
                    dateFormat: "mm-dd-yy",
                    showButtonBar: true,
                    showIcon: true,
                    onDateSelect: _cache[13] || (_cache[13] = ($event: any) => (_ctx.formatDate($event)))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, [
                  _cache[58] || (_cache[58] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Probability", -1)),
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.saleOpp.prob_pct,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.saleOpp.prob_pct) = $event)),
                    mode: "decimal",
                    minFractionDigits: 2,
                    maxFractionDigits: 2,
                    min: 0,
                    suffix: "%",
                    inputClass: "text-left"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, [
                  _cache[59] || (_cache[59] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Priority", -1)),
                  _createVNode(_component_Dropdown, {
                    modelValue: _ctx.saleOpp.priority,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.saleOpp.priority) = $event)),
                    options: _ctx.control.saleOpportunity.priority_items,
                    optionLabel: "priority",
                    optionValue: "priority",
                    showClear: _ctx.saleOpp.priority != ''
                  }, null, 8, ["modelValue", "options", "showClear"])
                ])
              ]),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("div", _hoisted_42, [
                  _cache[60] || (_cache[60] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Recurring $", -1)),
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.saleOpp.recurring_amt,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.saleOpp.recurring_amt) = $event)),
                    minFractionDigits: 2,
                    maxFractionDigits: 2,
                    mode: "currency",
                    currency: "USD",
                    locale: "en-US"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_43, [
                _createElementVNode("div", _hoisted_44, [
                  _cache[61] || (_cache[61] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Follow Up", -1)),
                  _createVNode(_component_Calendar, {
                    modelValue: _ctx.saleOpp.follow_up_date,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.saleOpp.follow_up_date) = $event)),
                    dateFormat: "mm-dd-yy",
                    showButtonBar: true,
                    showIcon: true,
                    onDateSelect: _cache[18] || (_cache[18] = ($event: any) => (_ctx.formatDate($event, 'follow_up_date')))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_45, [
                _createElementVNode("div", _hoisted_46, [
                  _cache[62] || (_cache[62] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Source", -1)),
                  _createVNode(_component_Dropdown, {
                    modelValue: _ctx.saleOpp.source,
                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.saleOpp.source) = $event)),
                    options: _ctx.control.saleOpportunity.source_items,
                    optionLabel: "source",
                    optionValue: "source",
                    showClear: _ctx.saleOpp.source != ''
                  }, null, 8, ["modelValue", "options", "showClear"])
                ])
              ]),
              _createElementVNode("div", _hoisted_47, [
                _createElementVNode("div", _hoisted_48, [
                  _cache[63] || (_cache[63] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Type", -1)),
                  _createVNode(_component_Dropdown, {
                    modelValue: _ctx.saleOpp.type,
                    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.saleOpp.type) = $event)),
                    options: _ctx.control.saleOpportunity.type_items,
                    optionLabel: "type",
                    optionValue: "type",
                    showClear: _ctx.saleOpp.type != ''
                  }, null, 8, ["modelValue", "options", "showClear"])
                ])
              ]),
              _createElementVNode("div", _hoisted_49, [
                _createElementVNode("div", _hoisted_50, [
                  _cache[64] || (_cache[64] = _createElementVNode("span", { class: "p-inputgroup-addon medium-addon" }, "Assigned To", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.saleOpp.assigned_to,
                    "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.saleOpp.assigned_to) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_51, [
                _createVNode(_component_TextArea, {
                  rows: "5",
                  class: "opp-notes",
                  placeholder: "Enter your notes here...",
                  modelValue: _ctx.saleOpp.notes,
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.saleOpp.notes) = $event))
                }, null, 8, ["modelValue"])
              ])
            ]),
            (!_ctx.isReadOnly)
              ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                  _cache[66] || (_cache[66] = _createElementVNode("span", { style: {"font-style":"italic"} }, "Save Opportunity to enable attachments, quotes, orders and log entries.", -1)),
                  _createVNode(_component_Button, {
                    label: "Save",
                    icon: "pi pi-check",
                    onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.save())),
                    loading: _ctx.submitLoading
                  }, null, 8, ["loading"])
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.isReadOnly)
            ? (_openBlock(), _createBlock(_component_CollapsibleSection, {
                key: 0,
                isOpen: _ctx.sectionsStatus.quotesAndSales,
                header: "Quotes and Orders",
                "onUpdate:isOpen": _cache[30] || (_cache[30] = ($event: any) => (_ctx.toggleSectionIcon('quotesAndSales')))
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_53, [
                    _createElementVNode("div", _hoisted_54, [
                      (_ctx.isQuotesToShowLoading)
                        ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                            key: 0,
                            class: "col-12 mt-4 h-4rem"
                          }))
                        : _createCommentVNode("", true),
                      (!_ctx.isQuotesToShowLoading)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                            _createVNode(_component_DataTable, {
                              value: _ctx.quotesToShow,
                              class: "col-12 mt-3 quotes-table",
                              responsiveLayout: "stack",
                              breakpoint: "1180px",
                              onRowClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.openTableElement($event.index, 'quotes'))),
                              onRowReorder: _cache[26] || (_cache[26] = ($event: any) => (_ctx.onReorderItems($event, 'quotes')))
                            }, {
                              header: _withCtx(() => [
                                _createElementVNode("div", _hoisted_56, [
                                  _cache[67] || (_cache[67] = _createElementVNode("h4", { class: "m-0" }, "Quotes", -1)),
                                  _createVNode(_component_SplitButton, {
                                    label: "Create Quote",
                                    icon: "pi pi pi-plus",
                                    onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.createQuote())),
                                    disabled: !_ctx.isReadOnly,
                                    model: _ctx.getSplitButtonItems
                                  }, null, 8, ["disabled", "model"])
                                ])
                              ]),
                              empty: _withCtx(() => _cache[68] || (_cache[68] = [
                                _createElementVNode("h4", { class: "m-0 text-center font-normal" }, " No quotes to show. ", -1)
                              ])),
                              default: _withCtx(() => [
                                _createVNode(_component_Column, {
                                  rowReorder: "",
                                  headerStyle: "width: 1rem",
                                  reorderableColumn: false
                                }),
                                _createVNode(_component_Column, {
                                  field: "id",
                                  header: "ID",
                                  bodyClass: "text-center"
                                }),
                                _createVNode(_component_Column, {
                                  field: "date",
                                  header: "Date",
                                  bodyClass: "text-center"
                                }),
                                _createVNode(_component_Column, {
                                  field: "customer",
                                  header: "Customer",
                                  bodyClass: "text-center"
                                }),
                                _createVNode(_component_Column, {
                                  field: "status",
                                  header: "Status",
                                  bodyClass: "text-center justify-items-center"
                                }, {
                                  body: _withCtx((slotProps) => [
                                    _withDirectives(_createElementVNode("div", { class: "status-column" }, _toDisplayString(_ctx.getStatusDescription(slotProps.data.status)), 513), [
                                      [_vShow, slotProps.data.status]
                                    ])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_Column, {
                                  field: "actions",
                                  header: "Actions",
                                  bodyClass: "text-center"
                                }, {
                                  body: _withCtx(({ data, index }) => [
                                    _createElementVNode("div", _hoisted_57, [
                                      _createElementVNode("div", _hoisted_58, [
                                        _createVNode(_component_SpeedDial, {
                                          direction: "left",
                                          mask: true,
                                          tooltipOptions: {
                                position: 'top',
                                event: 'hover',
                              },
                                          model: [
                                {
                                  label: `Create Order from Quote ${data.id}`,
                                  tooltip: `Create Order from Quote ${data.id}`,
                                  icon: 'pi pi-copy',
                                  command: () => {
                                    _ctx.convertQuoteToOrder(data);
                                  },
                                },
                                {
                                  label: `Email quote: ${data.id} pdf`,
                                  tooltip: `Email quote: ${data.id} pdf`,
                                  icon: 'pi pi-envelope',
                                  command: () => {
                                    _ctx.handleEmailPDF(data.id, 'quote');
                                  },
                                },
                                {
                                  label: `Download quote: ${data.id} pdf`,
                                  tooltip: `Download quote: ${data.id} pdf`,
                                  icon: _ctx.handlePDFIcons(data.id, 'quote'),
                                  command: () => {
                                    _ctx.downloadQuotePDF(data.id);
                                  },
                                },
                                {
                                  label: `Remove Quote ${data.id}`,
                                  tooltip: `Remove Quote ${data.id}`,
                                  icon: 'pi pi-trash',
                                  command: () => {
                                    _ctx.deleteTableElement(index, 'quotes');
                                  },
                                },
                              ]
                                        }, null, 8, ["model"]),
                                        _withDirectives(_createVNode(_component_ProgressSpinner, { class: "h-1rem" }, null, 512), [
                                          [_vShow, 
                                _ctx.isIconLoading(data.id, 'mailingQuotes') ||
                                _ctx.isIconLoading(data.id, 'convertingQuotes') ||
                                _ctx.handlePDFLoading(data.id, 'quote')
                              ]
                                        ])
                                      ])
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["value"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_59, [
                      (_ctx.isOrdersToShowLoading)
                        ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                            key: 0,
                            class: "col-12 mt-4 h-4rem"
                          }))
                        : _createCommentVNode("", true),
                      (!_ctx.isOrdersToShowLoading)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
                            _createVNode(_component_DataTable, {
                              value: _ctx.ordersToShow,
                              responsiveLayout: "stack",
                              breakpoint: "1180px",
                              class: "col-12 mt-3 so-table",
                              onRowClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.openTableElement($event.index, 'orders'))),
                              onRowReorder: _cache[29] || (_cache[29] = ($event: any) => (_ctx.onReorderItems($event, 'orders')))
                            }, {
                              header: _withCtx(() => [
                                _createElementVNode("div", _hoisted_61, [
                                  _cache[69] || (_cache[69] = _createElementVNode("h4", { class: "m-0" }, "Orders", -1)),
                                  _createVNode(_component_Button, {
                                    icon: "pi pi-plus",
                                    label: "Link Order",
                                    iconPos: "left",
                                    class: "text-sm",
                                    onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.toggleAddItemDialog(true, 'orders')))
                                  })
                                ])
                              ]),
                              empty: _withCtx(() => _cache[70] || (_cache[70] = [
                                _createElementVNode("h4", { class: "m-0 text-center font-normal" }, " No orders to show. ", -1)
                              ])),
                              default: _withCtx(() => [
                                _createVNode(_component_Column, {
                                  rowReorder: "",
                                  headerStyle: "width: 1rem",
                                  reorderableColumn: false
                                }),
                                _createVNode(_component_Column, {
                                  field: "so_id",
                                  header: "ID",
                                  bodyClass: "text-center"
                                }),
                                _createVNode(_component_Column, {
                                  field: "date",
                                  header: "Date",
                                  bodyClass: "text-center"
                                }, {
                                  body: _withCtx((slotProps) => [
                                    (slotProps.data.date)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_62, _toDisplayString(slotProps.data.date), 1))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_63, "NA"))
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_Column, {
                                  field: "customer",
                                  header: "Customer",
                                  bodyClass: "text-center"
                                }, {
                                  body: _withCtx((slotProps) => [
                                    (slotProps.data.customer)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_64, _toDisplayString(slotProps.data.customer), 1))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_65, "NA"))
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_Column, {
                                  field: "actions",
                                  header: "Actions",
                                  bodyClass: "text-center"
                                }, {
                                  body: _withCtx(({ data, index }) => [
                                    _createElementVNode("div", _hoisted_66, [
                                      _createElementVNode("div", _hoisted_67, [
                                        _createVNode(_component_SpeedDial, {
                                          direction: "left",
                                          mask: true,
                                          tooltipOptions: {
                                position: 'top',
                                event: 'hover',
                              },
                                          model: [
                                {
                                  label: `Email Order: ${data.so_id} pdf`,
                                  tooltip: `Email Order: ${data.so_id} pdf`,
                                  icon: 'pi pi-envelope',
                                  command: () => {
                                    _ctx.handleEmailPDF(data.so_id, 'order');
                                  },
                                },
                                {
                                  label: `Download Order: ${data.so_id} pdf`,
                                  tooltip: `Download Order: ${data.so_id} pdf`,
                                  icon: _ctx.handlePDFIcons(data.so_id, 'order'),
                                  command: () => {
                                    _ctx.getSalesOrderPDF(data.so_id);
                                  },
                                },
                                {
                                  label: `Remove Order ${data.so_id}`,
                                  tooltip: `Remove Order ${data.so_id}`,
                                  icon: 'pi pi-trash',
                                  command: () => {
                                    _ctx.deleteTableElement(index, 'orders');
                                  },
                                },
                              ]
                                        }, null, 8, ["model"]),
                                        _withDirectives(_createVNode(_component_ProgressSpinner, { class: "h-1rem" }, null, 512), [
                                          [_vShow, 
                                _ctx.isIconLoading(data.so_id, 'mailingOrders') ||
                                _ctx.handlePDFLoading(data.so_id, 'order')
                              ]
                                        ])
                                      ])
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["value"])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["isOpen"]))
            : _createCommentVNode("", true),
          (_ctx.isReadOnly)
            ? (_openBlock(), _createBlock(_component_CollapsibleSection, {
                key: 1,
                isOpen: _ctx.sectionsStatus.logEntries,
                header: "Log Entries",
                "onUpdate:isOpen": _cache[31] || (_cache[31] = ($event: any) => (_ctx.toggleSectionIcon('logEntries')))
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_68, [
                    _createVNode(_component_LogsDataTable, {
                      elementId: _ctx.saleOpp.id,
                      file: "SALEOPP",
                      associatedTypes: _ctx.associatedTypes
                    }, null, 8, ["elementId", "associatedTypes"])
                  ])
                ]),
                _: 1
              }, 8, ["isOpen"]))
            : _createCommentVNode("", true),
          (_ctx.isReadOnly)
            ? (_openBlock(), _createBlock(_component_CollapsibleSection, {
                key: 2,
                isOpen: _ctx.sectionsStatus.attachments,
                header: "Attachments",
                "onUpdate:isOpen": _cache[34] || (_cache[34] = ($event: any) => (_ctx.toggleSectionIcon('attachments')))
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_69, [
                    _createElementVNode("div", _hoisted_70, [
                      _createVNode(_component_AttachmentRecords, {
                        class: "pt-0",
                        hostFile: "SALEOPP",
                        record: _ctx.saleOpp,
                        isLogHidden: false,
                        onOnDeleted: _cache[32] || (_cache[32] = 
                    (event) => {
                      _ctx.handleDeleted(event);
                    }
                  ),
                        onOnSaved: _cache[33] || (_cache[33] = 
                    (event) => {
                      _ctx.handleSaved(event);
                    }
                  )
                      }, null, 8, ["record"])
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["isOpen"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showAddQuote,
      "onUpdate:visible": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.showAddQuote) = $event)),
      header: "Search for Quote",
      modal: true,
      closable: false,
      class: "w-auto",
      onHide: _cache[39] || (_cache[39] = 
      () => {
        _ctx.showAddQuote = false;
      }
    )
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _cache[36] || (_cache[36] = 
          () => {
            _ctx.selectedQuote = {} as SOQuote;
            _ctx.showAddQuote = false;
          }
        ),
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Add",
          icon: "pi pi-check",
          onClick: _cache[37] || (_cache[37] = ($event: any) => (_ctx.addElementToTable('quotes')))
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_QuoteAutocomplete, {
          onItemSelect: _cache[35] || (_cache[35] = ($event: any) => (_ctx.handleElementSelected($event, 'quotes'))),
          ref: "quoteAutocompleteRef",
          class: "quote-search"
        }, null, 512)
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showAddOrder,
      "onUpdate:visible": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.showAddOrder) = $event)),
      header: "Search for Order",
      modal: true,
      closable: false,
      class: "w-auto",
      onHide: _cache[44] || (_cache[44] = 
      () => {
        _ctx.showAddOrder = false;
      }
    )
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _cache[41] || (_cache[41] = 
          () => {
            _ctx.selectedOrder = {};
            _ctx.showAddOrder = false;
          }
        ),
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Add",
          icon: "pi pi-check",
          onClick: _cache[42] || (_cache[42] = ($event: any) => (_ctx.addElementToTable('orders')))
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_OrderAutocomplete, {
          onItemSelect: _cache[40] || (_cache[40] = ($event: any) => (_ctx.handleElementSelected($event, 'orders'))),
          class: "quote-search"
        })
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_EmailFileDialog, {
      show: _ctx.showEmailDialog,
      header: _ctx.getEmailDialogTitle(_ctx.mailType, _ctx.elementIdToEmail),
      onHide: _cache[45] || (_cache[45] = 
      (isHidden) => {
        _ctx.showEmailDialog = isHidden;
        _ctx.mailType = '';
      }
    ),
      fileId: _ctx.elementIdToEmail,
      fileName: _ctx.elementIdToEmail,
      onOnEmail: _cache[46] || (_cache[46] = (data) => _ctx.handleSendEmail(data, _ctx.mailType))
    }, null, 8, ["show", "header", "fileId", "fileName"])
  ], 64))
}